import { Injectable } from '@angular/core';
import { environment } from 'projects/evolutics-shared-lib/src/environments/environment';
import { map } from 'rxjs/operators';
import { ITableName, InputType } from '@Shared/models/index.model';
import { ApiService } from './api.service';
import {
  IFieldNameKVP,
  ReportFieldType,
} from '@Reusables/reusable-pages/Report/report-extras/report.model';

@Injectable({
  providedIn: 'root',
})
export class TableRelationsService {
  baseURL = environment.apiBaseUrl + '/rest/tables/relation/';
  readonly reportFieldTypeToInputTypeMap: { [k in ReportFieldType]: InputType } = {
    BOOLEAN: 'checkbox',
    TEXT: 'text',
    DATE: 'date',
    NUMBER: 'number',
  };
  constructor(private apiService: ApiService) {}

  getAllTableRelations = () => {
    return this.apiService.getWithLocalCache<ITableRelation[]>(`${this.baseURL}all`);
  };
  getAllTableGroups = () => {
    return this.apiService.getWithLocalCache<string[]>(`${this.baseURL}table-group/all`);
  };
  getTablesByTableGroup = (tableGroup: string) => {
    return this.apiService
      .getWithLocalCache<ITableName[]>(`${this.baseURL}table/${tableGroup}`)
      .pipe(
        map((r) =>
          r.sort3('primaryTable').map<ITableName>((x) => ({ _label: x.primaryTable?.split('_').join(' '), ...x })),
        ),
      );
  };
  getFieldNames = (tableName: string, schema: string = 'CRM') => {
    return this.apiService
      .getWithLocalCache<
        IFieldNameKVP[]
      >(environment.apiBaseUrl + `/rest/reports/${tableName}/${schema}/all-column`)
      .pipe(
        map((x) =>
          x
            .sort2('columnName', true)
            .map((r) => ({ ...r, _inputType: this.reportFieldTypeToInputTypeMap[r.dataType] || 'text' })),
        ),
      );
  };
  getAllTables = () => {
    return this.apiService.getFromMemory<string[]>(`${this.baseURL}table/all`);
  };
}

export interface ITableRelation {
  primaryTable: string;
  secondaryTable: null | string;
  connector1: null | string;
  connector2: null | string;
  tableGroup: string;
  processConnector: null | string;
  schema: string;
  warehouse: null;
  id: number;
  description: null | string;
}